import {
  IWidgetController,
  I$WWrapper,
} from '@wix/native-components-infra/dist/src/types/types';
import { debounce } from 'lodash';
import { FederatedSuggestions, SuggestionComponentType } from '@wix/search-box';

import { IWidgetControllerConfig } from './platform.types';
import { createSearchPlatformBiLogger } from './bi';
import { ISearchResultsWixCode } from './searchResultsControllerFactory';
import { convertResponseToSuggestions } from './convertResponseToSuggestions';

interface I$W extends Pick<I$WWrapper, 'props'> {
  (key: string): any;
}

type I$WResult<T> = T[] & T;
interface I$WEvent<T, S = {}> {
  target: T;
  syntheticEvent: S;
}

interface ISearchBoxWixCode {
  onSubmit(handler: (e: I$WEvent<ISearchBoxWixCode>) => void): void;
  onChange(handler: (e: I$WEvent<ISearchBoxWixCode>) => void): void;

  onSuggestionSelect(
    handler: (
      e: I$WEvent<
        ISearchBoxWixCode,
        {
          data: {
            globalIndex: number;
            groupId: string;
            query: string;
            url: string;
            type: SuggestionComponentType;
          };
        }
      >,
    ) => void,
  ): void;

  onSuggestionsFooterClick(
    handler: (
      e: I$WEvent<
        ISearchBoxWixCode,
        {
          url: string;
          query: string;
        }
      >,
    ) => void,
  ): void;
  onSuggestionGroupSelect(
    handler: (
      e: I$WEvent<
        ISearchBoxWixCode,
        {
          url: string;
          query: string;
          groupId: string;
        }
      >,
    ) => void,
  ): void;

  suggestionsEnabled: boolean;
  suggestions: FederatedSuggestions | null;
  value: string;
}

export async function searchAppControllerFactory(
  controllerConfig: IWidgetControllerConfig,
): Promise<IWidgetController> {
  const {
    wixCodeApi,
    platformAPIs,
    searchSDK,
    searchLocation,
    reportError,
    getCategoryList,
  } = controllerConfig;
  // TODO: cleanup types when resolved issue with type
  // https://github.com/wix-private/site-search/issues/92
  const $w: I$W = controllerConfig.$w;
  const isMobile = wixCodeApi.window.formFactor === 'Mobile';

  const isSiteViewMode = wixCodeApi.window.viewMode === 'Site';
  const isDemoContent = !isSiteViewMode;

  const bi = createSearchPlatformBiLogger(
    platformAPIs,
    wixCodeApi,
    reportError,
  );

  return {
    async pageReady() {
      const absoluteUrl = await searchLocation.getSearchResultsAbsoluteUrl();

      function withBaseUrl(url: string) {
        return `${absoluteUrl}${url}`;
      }

      const searchBoxes: I$WResult<ISearchBoxWixCode> = $w('@searchBox');
      const searchResults: I$WResult<ISearchResultsWixCode> = $w(
        '@searchResults',
      );

      const hasSearchBoxOnPage = searchBoxes.length > 0;
      const isOnSearchResultsPage = searchResults.length > 0;

      if (!hasSearchBoxOnPage) {
        return;
      }

      searchBoxes.onSubmit(e => {
        const searchBox = e.target;
        const searchQuery = searchBox.value;

        bi.searchSubmit({
          isDemoContent,
          searchQuery,
        });

        searchBox.value = '';

        if (!isOnSearchResultsPage) {
          // NOTE: navigation with not empty search query works correctly only in `Site` view mode
          // TODO: we could navigate with searchQuery in preview mode when `sv_editorNativeComponents` opened
          void searchLocation.navigateToSearchResults({
            query: isSiteViewMode ? searchQuery : '',
          });
        } else {
          // NOTE: editor version of searchResults component, doesn't return valid controller instance
          // so `changeQuery` call does not affect search results component
          // should be fixed when `sv_editorNativeComponents` opened
          // https://github.com/wix-private/site-search/issues/130
          searchResults.changeQuery(searchQuery);
        }
      });

      /**
       * Clear all suggestions if mobile.
       * If switch to desktop editor script will set demo suggestion again
       */
      if (isMobile) {
        searchBoxes.suggestions = null;
      }

      if (!isDemoContent && !isMobile) {
        const loadSuggestions = async (e: I$WEvent<ISearchBoxWixCode>) => {
          const searchBox = e.target;
          const searchQuery = searchBox.value;

          if (!searchBox.suggestionsEnabled) {
            return;
          }

          if (searchQuery.length < 3) {
            searchBox.suggestions = null;
            return;
          }

          const biSearchBoxSuggestionsRequestFinished = bi.searchBoxSuggestionsRequestStarted(
            { searchQuery },
          );

          try {
            const federatedResponse = await searchSDK.getFederatedSuggestions({
              query: searchQuery,
              limit: 4,
            });
            const categoryList = await getCategoryList();

            const searchAllRelativeUrl = searchLocation.encodePath({
              query: searchQuery,
            });

            const searchAllUrl = withBaseUrl(`/${searchAllRelativeUrl}`);

            const federatedSuggestions = convertResponseToSuggestions({
              federatedResponse,
              categoryList,
              searchQuery,
              footerUrl: searchAllUrl,
              searchLocation,
              absoluteUrl,
            });

            searchBox.suggestions = federatedSuggestions;

            biSearchBoxSuggestionsRequestFinished({
              success: true,
              suggestions: federatedSuggestions.items,
            });
          } catch (error) {
            biSearchBoxSuggestionsRequestFinished({
              success: false,
              error: error.toString(),
              suggestions: [],
            });

            reportError(error);

            throw error;
          }
        };

        searchBoxes.onChange(debounce(loadSuggestions, 300));
        searchBoxes.onSubmit(e => {
          const searchBox = e.target;
          searchBox.suggestions = null;
        });

        searchBoxes.onSuggestionSelect(e => {
          const searchBox = e.target;
          const {
            url,
            query,
            globalIndex,
            groupId,
            type,
          } = e.syntheticEvent.data;

          if (type === 'item') {
            bi.searchBoxSuggestionClick({
              url,
              searchQuery: query,
              index: globalIndex,
              documentType: groupId,
              suggestions: searchBox.suggestions.items,
            });
          } else {
            bi.searchBoxSuggestionShowAllClick({
              searchQuery: query,
              documentType: groupId,
            });
          }

          const relativeUrl = url.replace(wixCodeApi.location.baseUrl, '');
          wixCodeApi.location.to?.(relativeUrl);
        });

        searchBoxes.onSuggestionsFooterClick(e => {
          const searchQuery = e.syntheticEvent.query;
          const url = e.syntheticEvent.url;
          const relativeUrl = url.replace(wixCodeApi.location.baseUrl, '');

          bi.searchBoxSuggestionSearchAllClick({
            searchQuery,
          });

          wixCodeApi.location.to?.(relativeUrl);
        });
      }
    },
  };
}
